import React from "react"
import { Box, Text } from "grommet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { MiBus } from "../components/miBus"
import { Features } from "../components/features/features"
import { SupportedBrands } from "../components/supportedBrands"
import RoutedButton from "../components/routedButton"
import { event } from "../helpers/analytics"
import Link from "../components/link"
import Image from "../components/image"

const IndexPage = () => {

  return (
    <Layout>
      <Seo title="Home" />
      <Box align="center" pad="large">
        <MiBus size='large'/>
        <Box align='center' gap='medium'>
          <Box width='medium'><Image /></Box>
          <RoutedButton margin={{top: 'medium'}} onClick={() => event('begin_checkout')} primary to='/order' label={<Text size='xxlarge'>Поръчай</Text>} size='large'/>
          <Link to='roadmap' size='large' label='Проследи развитието' color='accent-4'/>
        </Box>
        <Features/>
      </Box>
      <SupportedBrands/>
    </Layout>
  );
}

export default IndexPage
