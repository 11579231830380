import React from "react";
import { Box } from "grommet";
import { Tile } from "./tile";
import { Beacon, Domain, Transaction, Wifi } from "grommet-icons"

const data = [
  "miBus се свързва безжично чрез WiFi или 3G",
  "miBus поддържа постоянна двупосочна връзка с устройството Ви",
  "гледайте и управлявайте вашите устройства чрез интерактивната платформа на mitabits"
];

const Features = ({ ...rest }) => (
  <Box
    direction="row-responsive"
    justify="center"
    align="center"
    {...rest}
    margin={{ top: "xlarge" }}
  >
    <Tile direction="row" action="Wi-Fi | 3G" link='//en.wikipedia.org/wiki/Wi-Fi#:~:text=Wi%2DFi%20(%2F%CB%88w,exchange%20data%20by%20radio%20waves.' summary={data[0]} gap='medium'>
      <Wifi size='xlarge' color='status-ok'/>
      <Beacon size='xlarge' color='status-error'/>
    </Tile>
    <Tile direction="row" action="RS485 communication protocol" link='//en.wikipedia.org/wiki/RS-485' summary={data[1]}>
      <Transaction size='xlarge' color='brand'/>
    </Tile>
    <Tile direction="row" action="Dashboard" link='//locahost:3000' summary={data[2]}>
      {/* https://github.com/tommoor/react-emoji-render/pull/35 */}
       <Domain size='xlarge' color='accent-4'/>
    </Tile>
  </Box>
);

export { Features };