import React from "react"

import { Box, Heading, Paragraph, Image, Text } from "grommet"
import Link from "./link"
// import Image from "./image"

const brands = [
  {
    url: "//logos-world.net/wp-content/uploads/2020/04/Huawei-Logo-2018-present.jpg",
    name: "Huawei",
  },
  { url: "//www.skysolarenergy.com.au/wp-content/uploads/2019/05/Sky-Solar-Energy-Solar-Partners-Growatt-Company-Logo.png", name: "Growatt" },
  // '//v2.grommet.io/assets/IMG_4245.jpg',
  // '//v2.grommet.io/assets/IMG_4210.jpg',
  // 'https://avatars1.githubusercontent.com/u/14203820?s=280&v=4',
  // '//v2.grommet.io/assets/Wilderpeople_Ricky.jpg',
  // '//v2.grommet.io/assets/IMG_4245.jpg',
  // '//v2.grommet.io/assets/IMG_4210.jpg',
  // '//v2.grommet.io/assets/Wilderpeople_Ricky.jpg',
]

const SupportedBrands = ({ ...rest }) => (
  <Box align="center" pad="large">
    <Heading size="large">поддържани марки</Heading>
    <Paragraph size="xlarge" textAlign="center">
      списъкът се разширява с всеки изминал ден.
      ако имате устройство, чието производител не е споменат,
      <Link to='contact-us'>свържете се с нас</Link>, за да го добавим.
    </Paragraph>
    <Box align="center" justify="center" height="small" gap="small" direction="row" margin={{top: 'medium'}}>
      {brands.map((brand, index) => (
        <Box key={index} width='small' height='small' align='center' gap='medium'>
          <Image src={brand.url} fit="contain" opacity='strong'/>
          <Text>{brand.name}</Text>
        </Box>
      ))}
    </Box>
    {/*<Box direction="row-responsive" align="center" margin="large" {...rest}>*/}
    {/*  <Talent*/}
    {/*    name="swipe up or down"*/}
    {/*    summary="swipe up for up voting and down for down vote"*/}
    {/*    action="gestures"*/}
    {/*  >*/}
    {/*    <TalentEmoji name=":point_up_2::skin-tone-2:" />*/}
    {/*  </Talent>*/}
    {/*  <Talent*/}
    {/*    name="slide side to side"*/}
    {/*    summary="quickly move back and forth through the queue"*/}
    {/*    action="navigate"*/}
    {/*  >*/}
    {/*    <TalentEmoji name=":raised_hand::skin-tone-4:" />*/}
    {/*  </Talent>*/}
    {/*</Box>*/}
  </Box>
)

export { SupportedBrands }
